import Service from "../Service";
const resource = "HmnContractReport/";

export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    convertexcel(obj, requestID) {
        return Service.post(resource + "convertexcel", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID }
        })
    },

    list(parameters, requestID) {
        return Service.post(resource + "list", parameters, {
            params: { requestID: requestID },
        });
    },
};