<template>
    <v-card>
        <s-crud
			title="Reporte de Contratos"
			:config="config"
			:filter="filter"
			ref="gridAgreementReport"
			searchInput
		>
            <template v-slot:options>
				<v-tooltip bottom="">
					<template v-slot:activator="{ on }">
						<v-btn
						v-on="on"
						text
						black 
						@click="downloadExcel()"
						small
						>
							<v-icon style="font-size:14px;">mdi-file-excel</v-icon>
						</v-btn>
					</template>
					<span>Exportar a Excel</span>
				</v-tooltip>
            </template>
			<template v-slot:filter>
				<v-container>
					<v-row>
						<v-col>
							<s-date
								label="Fecha Inicio" 
								v-model="filter.AmsBeginDate" 
							></s-date>
						</v-col>
						<v-col>
							<s-date
								label="Fecha Fin" 
								v-model="filter.AmsEndDate" 
							></s-date>
						</v-col>
                        <v-col>
							<s-select-definition
								label="Estado"
								:def="1479"
								v-model="filter.AmsStatus"
								clearable
							></s-select-definition>
						</v-col>
                        <v-col>
							<s-select-definition
								label="Cat. Ocupacional"
								:def="1039"
                                :dgr="4"
								v-model="filter.TypeOccupationalCategory"
								clearable
							></s-select-definition>
						</v-col>
						<v-col>
							<s-select-area 	label="Area" v-model="filter.AreID"  ></s-select-area>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</s-crud>
    </v-card>
</template>

<script>
	import ContractReport 	from '@/services/HumanResource/ContractReport.js';
	import sTextarea from '@/components/Utils/sTextarea.vue';
	import SSelectArea from '@/components/Utils/SSelectArea.vue';

	export default {
        components: { 
        sTextarea,
        SSelectArea,
        },
		data() {
			return {
                data:[],
				filter: {
					AmsStatus: null,
					IsAdmin: this.$fun.isAdmin() ? 1 : 0,
					AreID: null,
					TypeOccupationalCategory:null,
				},
				config: {
					model: {
						AmsID			: "ID",
						AmsStartDateMovement	: "date",
						AmsEndDateMovement		: "date",
                        AmsTerminationDate : "date"
					},
					service: ContractReport,
					headers: [
						{text: "ID"									, value: "AmsID"				, draggable : false},
						{text: "ID Contrato"						, value: "AgtID"				},
						{text: "N° Doc"								, value: "PrsDocumentNumber"	},
						{text: "Nombres y Apellidos Completos"		, value: "NtpFullName"			},
						{text: "Area"								, value: "AreName"				},
						{text: "Cargo"								, value: "PstName"				},
						{text: "Fecha de Inicio de Movimiento"		, value: "AmsStartDateMovement"	},
						{text: "Fecha de Fin de Movimiento"	        , value: "AmsEndDateMovement"	},
						{text: "Fecha Cese"	                        , value: "AmsTerminationDate"	},
					]
				},
			}
		},
        methods: {
            downloadReport() {
                console.log("downloadReport");
            },
            async downloadExcel(){
                console.log("data", this.data);
                await ContractReport.list(this.filter, this.$fun.getUserID())
                    .then((r) => {
                        if (r.status == 200) {
                            if(r.data.length > 0){
                                console.log("r.data", r.data);
                                this.data = r.data
                            }
                        }
                    })

				this.$fun.alert("¿Seguro de descargar?", "question")
				.then(async r => {
					if(r.value){
						await ContractReport
						.convertexcel(this.data, this.$fun.getUserID())
						.then(resp => {
                            console.log("resp", resp);
							this.$fun.downloadFile(resp.data, this.$const.TypeFile.EXCEL, this.$fun.getUserID());
						})
					}
				})
			}
        }
    }
</script>